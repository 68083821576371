@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
*{
    margin:0px;
    padding:0px;
    box-sizing: border-box;

}

body{
    font-family: 'Poppins', sans-serif;
}
html{
    scroll-behavior: smooth;
    
}
::-webkit-scrollbar {
    width: 0px;
  }